import React from "react";
import SimpleMap from "./simpleMap";

const Address = () => {
  return (
    <div>
      <div className="font-['Roboto'] flex-1 ml-[24px]  p-4 lg:mb-10">
        <h1 className="lg:ml-[2.5rem] lg:mt-[2rem] text-[#1A2338] font-['Roboto'] lg:text-4xl md:text-lg font-bold">
          OUR ADDRESS
        </h1>
      </div>
      <div
        id="contact"
        className="lg:mb-0 mb-10 mx-[25px] lg:mx-0 flex lg:flex-row flex-col items-start space-y-4 lg:justify-between my-4"
      >
        <div
          style={{ fontFamily: "Lato, sans-serif" }}
          className="lg:w-[550px] flex flex-col lg:ml-[5rem]"
        >
          <p className="font-bold text-xl text-black lg:text-[32px]  lg:leading-[40.96px]">
            Suchitra Campus: Playschool and <br /> Nursery to Grade 5
          </p>
          <p className="text-base  mt-2 lg:mt-[10px] text-[#727272] lg:text-xl font-normal ">
            Customer Care No: <br />
            <span className="text-[#727272] text-normal">9515667682, 48567682</span>
          </p>
          <p className="text-base mt-2  lg:text-2xl font-normal leading-[30.72px] text-[#727272]">
          St. Michael’s School 149, Block No 6,Gayatri Nagar, Godavari
Homes
            <br />
            Jeedimetla,Secunderabad 500055
          </p>
        </div>
        <div className="lg:mr-[5rem]">
          <SimpleMap location={"17.50536088289122,78.47061368805355"} />
        </div>
      </div>
      {/* <div
        id="contact"
        className="lg:my-[140px] mx-[25px] lg:mx-0 flex lg:flex-row flex-col items-start space-y-4 lg:justify-between my-4"
      >
        <div
          style={{ fontFamily: "Lato, sans-serif" }}
          className="lg:w-1/2 flex flex-col lg:ml-[5rem]"
        >
          <p className="font-bold text-xl text-black lg:text-[32px] lg:leading-[40.96px]">
            Marredpally Campus: Playschool and <br />
            Nursery to Grade 5
          </p>
          <p className="text-base  mt-2 lg:mt-[10px] lg:text-xl font-normal text-[#727272]">
            Customer Care No: <br />
            <span className="text-[#727272] text-normal">
              040-27717682 , 040-66497682
            </span>
          </p>
          <p className="text-base  mt-2   font-normal lg:text-xl text-[#727272]">
            10-2-202 Street No. 5, , West Marredpally, Hyderabad - 500026
          </p>
        </div>
        <div className="lg:mr-[5rem]">
          <SimpleMap location={"17.45016896691001,78.51245495364655"} />
        </div>
      </div> */}
    </div>
  );
};

export default Address;
