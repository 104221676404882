import React from "react";

const Footer = () => {
  return (
    <div
      className="pt-10 lg:pt-5 font-['Roboto'] flex flex-col justify-center lg:w-full h-full lg:h-[20rem] bg-gradient-to-r from-[#345FC5] via-[#283B68] to-[#101625]"
      style={{
        backgroundImage:
          "linear-gradient(86.43deg, #345FC5 -12.84%, #283B68 37.49%, #28468B 86.49%, #101625 113.17%)",
      }}
    >
      <div className="font-['Roboto'] hidden lg:flex flex-row space-x-10 justify-around items-center px-6 py-6">
        <div>
          <div className="justify-center ml-5 lg:flex">
            <a href="/">
              <img
                src="/logo.png"
                alt="logo"
                className="2xl:w-[12rem] 2xl:h-[12rem] w-[10rem] h-[10rem] "
              />
            </a>
          </div>
        </div>
        <div className="">
          <h1 className="mb-4 font-bold text-white">Links</h1>
          <ul className="leading-8 text-[#fff] lg:mt-[25px] font-normal text-sm font-Lato">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/admissions">Admissions</a>
            </li>
            <li>
              <a href="/activities">Digital Classrooms</a>
            </li>
            <li>
              <a href="/contact">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="">
          <h1 className="mb-4 font-bold text-white">
            <a href="/Contact">Contact Us</a>
          </h1>
          <ul className="leading-8 text-[#fff] font-normal text-sm font-Lato">
            <li>
              <a href="/Contact">Suchitra Campus</a>
            </li>
            <li>Phone: 040 2786 2624</li>
            {/* <li>
              <a href="/Contact">Marredpally Campus</a>
            </li> */}

            {/* <li>Phone: 040 2786 2624</li> */}
          </ul>
        </div>
        <div className="">
          <h1 className="mb-4 font-bold text-white">
            {" "}
            <a href="/">Follow Us</a>
          </h1>
          <ul className="leading-8 text-[#fff] font-normal text-sm font-Lato">
            <li>
              <a href="https://www.facebook.com/Stmichaelsschoolsuchitra/">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/st.michaelsschool_suchitra/">
                Instagram
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@st.michaelsschoolgodavarih1821">
                YouTube
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/96638703/admin/feed/posts/">
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="">
          <h1 className="mb-4 font-bold text-white">
            <a href="/Privacy">Privacy Policy</a>
          </h1>
          <ul className="leading-8 text-[#fff] font-bold text-sm font-Lato">
            {/* <li>Terms and Conditions</li> */}
          </ul>
        </div>
      </div>

      <div className="  font-['Roboto'] lg:hidden grid-cols-2 gap-8 px-6">
        <div>
          <div className="flex flex-col lg:flex-row">
            <div className=" w-[156px] h-[131px] lg:mt-[53px] lg:ml-[20px] ">
              <a href="/">
                <img src="/logo.png" alt="logo" />
              </a>
            </div>
            <div className="my-5 ml-5 lg:ml-0">
              <h1 className="text-sm font-bold text-white">
                <a href="/Contact">Contact Us</a>
              </h1>
              <ul className="leading-8 text-[#fff] font-normal text-sm font-Lato">
                <li>
                  <a href="/Contact">Suchitra Campus</a>
                </li>
                <li>Phone: 040 2786 2624</li>
                {/* <li>
                  <a href="/Contact">Marredpally Campus</a>
                </li>
                <li>Phone: 040 2786 2624</li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:mt-[44px]">
          <div className="lg:ml-[41px] ml-5 my-5">
            <h1 className="font-bold text-white ">Links</h1>
            <ul className="leading-8 text-[#fff] font-normal text-sm font-Lato">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/admissions">Admissions</a>
              </li>
              <li>
                <a href="/activities">Digital Classrooms</a>
              </li>
              <li>
                <a href="/Contact">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="lg:ml-[45px] ml-5 my-5">
            <h1 className="font-bold text-white ">Follow Us</h1>
            <ul className="leading-8 text-[#fff] font-normal text-sm font-Lato">
              <li>
                <a href="https://www.facebook.com/Stmichaelsschoolsuchitra/">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/st.michaelsschool_suchitra/">
                  Instagram
                </a>
              </li>

              <li>
                <a href="https://www.youtube.com/@st.michaelsschoolgodavarih1821">
                  YouTube
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/96638703/admin/feed/posts/">
                  Linkedin
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col my-5 ml-5 lg:flex-row lg:ml-0">
          <h1 className="text-white font-bold text-sm lg:mt-[35px] ">
            <a href="/Privacy">Privacy Policy</a>
          </h1>
          <ul className="lg:mt-[35px] text-[#fff] font-bold text-sm font-Lato">
            {/* <li>Terms and Conditions</li> */}
          </ul>
        </div>
      </div>
      <a
        href="https://palnesto.com"
        target="_blank"
        rel="noreferrer"
        className="bg-white flex items-center justify-center h-full"
      >
        <img src="/palnesto-black-desktop.svg" alt="logo" className="w-60" />
      </a>
    </div>
  );
};

export default Footer;
