import React from "react";

const Headcontact = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(/contactImage.JPG)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className=" relative lg:w-full lg:h-screen w-full h-[222px] flex items-center px-[25px]"
      >
        <div className="absolute bottom-5 font-['Roboto'] bg-[#1A233880] rounded-2xl p-4">
          <p className=" lg:text-6xl text-lg  font-['Roboto'] font-extrabold text-[#FFA901]">
            Contact Us
          </p>
        </div>
      </div>
    </>
  );
};

export default Headcontact;
